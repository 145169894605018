import {
  Box,
  Button,
  Card,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  WixDesignSystemProvider,
  Image,
  FormField,
  ToggleSwitch,
  CircularProgressBar,
  Notification,
  Modal,
  CustomModalLayout,
  Cell
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import React, { useEffect } from "react";
import HeroImage from "./readable.png";
import { ReactTagManager } from "react-gtm-ts";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};


function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [trialRemainingDate, setTrialRemainingDate] = React.useState(0);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [InteractiveDemoOpen, setInteractiveDemoOpen] =
  React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const isFree = instanceData?.instance?.isFree !== false;

  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
    getTrial();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setSettings();
    } else {
      setIsLoading(false);
    }
  }, [isEnabled]);

  const BASE_URL = `https://certifiedcode.wixsite.com/readable/_functions`;
  const openInteractiveDemo = () => {
    setInteractiveDemoOpen(true);
  };
  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        setIsUpgradeBannerOpen(data.instance.isFree !== false);
      })
      .catch(() => {});
  }

  function getSettings() {
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsEnabled(data.isEnabled);
      })
      .catch(() => {});
  }

  function setSettings() {
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: isEnabled,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsEnabled(data.isEnabled);
      })
      .catch(() => {});
  }

  function getTrial() {
    fetch(`${BASE_URL}/premium`, {
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTrialRemainingDate(data?.trial);
      });
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=865ea807-f2a8-4aa2-9df8-94aee1168023&redirectUrl=https://certifiedcode.wixsite.com/readable/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"865ea807-f2a8-4aa2-9df8-94aee1168023"}?appInstanceId=${
    (instanceData as any)?.instance?.instanceId
  }`;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
          {`Upgrade to unlock "Access to all features of Readable"`}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          Upgrade
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Card>
        {/* Interactive DEmo Modal */}
      <Modal
            isOpen={InteractiveDemoOpen}
            screen="desktop"
            shouldDisplayCloseButton={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setInteractiveDemoOpen(false)}
          >
             <CustomModalLayout
              removeContentPadding={true}
              content={
                <div style={{ position: "relative", paddingBottom: "calc(56.22% + 41px)", height: 0, width: "100%" }}>
                  <iframe
                    src="https://app.arcade.software/share/Ln5AjHWqnJCo14UySVwG?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=true"
                    title="Get Paid with Readable| Getting Started"
                    frameBorder="0"
                    loading="lazy" 
                    
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light" }}
                  ></iframe>
                </div>
              }
            />
      </Modal>
       {/* Interactive DEmo Modal */}
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="550px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title="Readable: WCAG Accessibility"
                content="Readable is a WCAG accessibility tool that helps you make your website more accessible to people with disabilities."
                actions={
                  <Box direction="vertical" gap="SP1">
                    <FormField label="Enable Readable" labelPlacement="left">
                      <ToggleSwitch
                        checked={isEnabled}
                        onChange={() => setIsEnabled(!isEnabled)}
                      />
                    </FormField>
                    <Card>
                      <Card.Content>
                        <CircularProgressBar
                          label={
                            isUpgraded
                              ? "You're on Premium"
                              : `You have ${trialRemainingDate} days left on your trial`
                          }
                          skin={isUpgraded ? "standard" : "premium"}
                          showProgressIndication
                          value={isUpgraded ? 100 : 1 - trialRemainingDate / 30}
                        />
                      </Card.Content>
                    </Card>
                    <Button
                      skin="premium"
                      as="a"
                      href={upgradeUrl}
                      target="_blank"
                    >
                      Upgrade Now
                    </Button>
                    <Button
                      skin="inverted"
                      as="a"
                      href="https://bridget.reviews.certifiedcode.us/865ea807-f2a8-4aa2-9df8-94aee1168023"
                      target="_blank"
                    >
                      Rate Readable 5 stars
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
          image={<Image transparent borderRadius={0} src={HeroImage} />}
        />
      </Card>
       {/* Interactive DEmo Card */}
          <Cell span={12}>  
            <Card>
              <MarketingPageLayout
                removeImageHorizontalPadding
                removeImageVerticalPadding
                content={
                  <Box  marginTop= "-30px" verticalAlign="middle">
                    <MarketingPageLayoutContent
                      size="medium"
                      title="Learn how to setup Readable"
                      content="Estimated time: 5 minutes"
                      actions={
                        <Box marginTop="-30px">
                        <Button
                          size="small"
                          skin="premium"
                          onClick={openInteractiveDemo}
                        >
                          Watch video
                        </Button>
                      </Box>
                      }
                    />
                  </Box>
                }
              />
            </Card>
          </Cell>
       {/* Interactive DEmo Card */}
    </WixDesignSystemProvider>
  );
}

export default App;
